let BASEURL = localStorage.getItem("BASEURL");
let BASEURL2 = localStorage.getItem("BASEURL2");
let VERIFY_API_URL = localStorage.getItem("VERIFY_API_URL");

// 登入
const LOGIN_API = `${BASEURL}/account/loginapi`;
// 登出
const LOGOUT_API = `${BASEURL}/account/logoutapi`;
//註冊
const REGISTER_API = `${BASEURL}/account/registerapi`;
// 註冊後驗證
const VERIFY_API = `${BASEURL}/account/RegisterConfirmapi`;
//忘記密碼
const RECOVERY_API = `${BASEURL}/account/ForgetPassApi`;
const CONFIRMRESETPASSWORD_API = `${BASEURL}/account/ConfirmResetPasswordApi`;
const RECONFIRMRESETPASSWORD_API = `${BASEURL}/Profile/ChangePassword2Api`;
const CHANGEPASSWORD_API = `${BASEURL}/Profile/ChangePassword2Api`;

// 轉換語言
const CHANGELANG_API = `${BASEURL}/api/setculture`;

//更改預設語系時回傳後端
const CULTUERSET_API = `${BASEURL}/api/User/CultuerSet`;

// 盤查計畫
const LCALIST_API = `${BASEURL}/api/LCA/GetMyLCAs/`;
// 新增盤查計畫
const CREATEORGANIZATIONLCA_API = `${BASEURL}/api/LCA/CreateOrganizationLCA`;
const GETCERTIFICATIONCOMPANY_API = `${BASEURL}/api/Company/getCertificationCompany`;
const GETFAB_API = `${BASEURL}/api/Fab/GetAll`;
const GETARVERSION_API = `${BASEURL}/api/ARversion/GetAll`;
const GETLCADETAIL_API = `${BASEURL}/api/LCA/GetLCADetail`;

//修改盤查計畫
const UPDATELCADETAIL_API = `${BASEURL}/api/LCA/SaveOrganizationLCA`;

// 各類別資料取得
// 取得係數
const GETPARAMETER_API = `${BASEURL}/api/Parameter/getParameterValues`;
// 上、下游
const GETLIFECYCLE_API = `${BASEURL}/api/LifecycleAssmt/GetByLcaId`; // 商務旅行 貨物上游運輸及配送
const GETCOMMUTING_API = `${BASEURL}/api/EmployeeCommuting/GetEmployeeDataByLcaIdAndGHGinventoryItemNo`; // 通勤

//公司資料
const ENTERPRISEINFO_API = `${BASEURL}/Profile/Companyapi`;
const ENTERPRISELIST_API = `${BASEURL}/api/Fab/GetAll`;
const ENTERPRISELIST_ADD_API = `${BASEURL}/api/Fab/Save2`;
const ENTERPRISELIST_DEL_API = `${BASEURL}/api/Fab/Delete`;
const ENTERPRISELIST_SETTING_API = `${BASEURL}/Profile/Settingsapi`;
//係數庫管理
const GETPARAMETERCATEGORY_API = `${BASEURL}/api/parameter/GetVersionCategory`;
const GETTYPEWITHOUTPARAMETER_API = `${BASEURL}/api/parameter/GetTypeWithoutParameter`;
const GETPARAMETERTYPE_API = `${BASEURL}/api/parameter/GetParameter`;
const GETPARAMETERBYENCODING_API = `${BASEURL}/api/Parameter/GetParametersByEncoding`;
const GETSIMAPROPARAMETER_API = `${BASEURL}/api/LifecycleAssmt/GetParameterDetails`;
//顯著性分析
const ADDASSMTFACTOR_API = `${BASEURL}/api/AssmtFactor/Save`;
const DEL_ORISIGNIFICANCE_API = `${BASEURL}/api/SignificanceAssmt/Delete`;
const DEL_GRPSIGNIFICANCE_API = `${BASEURL}/api/SignificanceAssmt/DeleteGroup`;
const EDITSIGNIFICANCEAAAMT_API = `${BASEURL}/api/SignificanceAssmt/Save`;
const DELETEASSMTFACTOR_API = `${BASEURL}/api/AssmtFactor/Delete`;
const GETASSMTFACTOR_API = `${BASEURL}/api/AssmtFactor/GetByLcaId/`;
const GETSIGNIFICANC_API = `${BASEURL}/api/SignificanceAssmt/Sync`;
const SaveOrig_API = `${BASEURL}/api/SignificanceAssmt/Save`;
const SaveGroup_API = `${BASEURL}/api/SignificanceAssmt/SaveGroup`;
const Get_All_CycleStage = `${BASEURL}/api/SignificanceAssmt/GetAllCycleStage`;
const Get_Transport_CycleStage = `${BASEURL}/api/SignificanceAssmt/GetTransportCycleStage`;
const Get_Employee_CycleStage = `${BASEURL}/api/SignificanceAssmt/GetEmployeeCycleStage`;

//使用者資料
const USERINFO_API = `${BASEURL}/Profileapi`;
const USERLIST_API = `${BASEURL}/api/User/GetByMyCompany`;
const ADDUSER_API = `${BASEURL}/api/User/SaveNewUser`;
const DELUSER_API = `${BASEURL}/api/User/Delete`;
const MODIFYUSER_API = `${BASEURL}/api/User/EditUser`;
const CHANGEPWD_API = `${BASEURL}/Profile/changepasswordapi`;

// 事業
const GETWORKHOUR_API = `${BASEURL}/api/WorkHour/GetWorkHours`; // 工時
const GETPOWERUSAGE_API = `${BASEURL}/api/PowerUsage/GetPowerUsages`; // 用電量
const GETVEHICLE_API = `${BASEURL}/api/Vehicle/GetByLcaId`; // 車輛
const GETKITCHEN_API = `${BASEURL}/api/Kitchen/GetByLcaIdAndWarmGasType`; // 油氣燃料 // 製程
const GETKITCHENFORCOPY_API = `${BASEURL}/api/Kitchen/GetByLcaId`;
const GETFIREEQUIPMENT_API = `${BASEURL}/api/FireEquipment/GetByLcaId`; // 消防
const GETREFRIGERANT_API = `${BASEURL}/api/Refrigerant/GetByLcaId`; // 冷媒
const GETSTEAMUSAGE_API = `${BASEURL}/api/SteamUsage/GetSteamUsagesapi`; // 蒸氣
const GETOTHERCOMPOUND_API = `${BASEURL}/api/OtherCompound/GetByLcaId`; // 其他關注類
const GETPURCHASEDPRODUCT_API = `${BASEURL}/api/PurchasedProduct/GetPurchasedProductByLcaId`; //原料採購
const GETUPSTREAMTRANSPORT_API = `${BASEURL}/api/MaterialTransport/GetMaterialTransportByLcaIdAndType`; // 3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
// 新增
const POSTREFRIGERANT_API = `${BASEURL}/api/Refrigerant/Save`; // 冷媒
const POSTFIREEQUIPMENT_API = `${BASEURL}/api/FireEquipment/Save`; // 消防
const POSTKITCHEN_API = `${BASEURL}/api/Kitchen/Save`; // 油氣燃料
const POSTVEHICLE_API = `${BASEURL}/api/Vehicle/Save`; // 移動源
const POSTOTHERCOMPOUND_API = `${BASEURL}/api/OtherCompound/Save`; // 其他關注類
const POSTLIFECYCLE_API = `${BASEURL}/api/LifecycleAssmt/Save`; // C3 ~ C6
const POSTCOMMUTING_API = `${BASEURL}/api/EmployeeCommuting/Save`; // 員工通勤
const POSTPURCHASEDPRODUCT_API = `${BASEURL}/api/PurchasedProduct/Save`; // 原料採購
const POSTUPSTREAMTRANSPORT_API = `${BASEURL}/api/MaterialTransport/Save`; // 3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
// 修改
const UPDATEWORKHOUR_API = `${BASEURL}/api/WorkHour/SaveWorkHours`; // 工時
const UPDATEPOWERUSAGE_API = `${BASEURL}/api/PowerUsage/SavePowerUsages`; // 一般用電量
const UPDATEGREENPOWERUSAGE_API = `${BASEURL}/api/PowerUsage/SaveGreenPowerUsages`; // 綠電
const UPDATESTEAMUSAGE_API = `${BASEURL}/api/SteamUsage/SaveSteamUsagesRaw`; // 蒸氣
// 刪除
const DELETEVEHICLE_API = `${BASEURL}/api/Vehicle/Delete`; // 車輛
const DELETEREFRIGERANT_API = `${BASEURL}/api/Refrigerant/Delete`; // 冷媒
const DELETEFIREEQUIPMENT_API = `${BASEURL}/api/FireEquipment/Delete`; // 消防
const DELETEKITCHEN_API = `${BASEURL}/api/Kitchen/Delete`; // 油氣燃料
const DELETEOTHERCOMPOUND_API = `${BASEURL}/api/OtherCompound/Delete`; // 其他關注類
const DELETELIFECYCLE_API = `${BASEURL}/api/LifecycleAssmt/Deleteapi`; // C3 ~ C6
const DELETEPURCHASEDPRODUCT_API = `${BASEURL}/api/PurchasedProduct/DeletePurchasedProduct`; // 原料採購
const DELETEUPSTREAMTRANSPORT_API = `${BASEURL}/api/MaterialTransport/DeleteMaterialTransport`; // 3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
const DELETECOMMUTING_API = `${BASEURL}/api/EmployeeCommuting/Delete`; // 員工通勤
const GETALLCYCLESTAGE_API = `${BASEURL}/api/SignificanceAssmt/GetAllCycleStage`; // 風險評估

//排放源匯入 Excel
const WORKHOUR_EXCEL_UPLOAD_API = `${BASEURL}/api/WorkHour/WorkHourExcelCheck`; // 工時
const WORKHOUR_EXCEL_IMPORT_API = `${BASEURL}/api/WorkHour/SaveAllExcelImport`;
const POWERUSAGE_EXCEL_UPLOAD_API = `${BASEURL}/api/PowerUsage/PowerUsageExcelCheck`; // 用電量 PowerUsage/PowerUsageExcelCheck/227/台灣/2021
const POWERUSAGE_EXCEL_IMPORT_API = `${BASEURL}/api/PowerUsage/SaveAllExcelImport`;
const FIREEQUIPMENT_EXCEL_UPLOAD_API = `${BASEURL}/api/FireEquipment/FireEquipmentExcelCheck`; // 消防
const FIREEQUIPMENT_EXCEL_IMPORT_API = `${BASEURL}/api/FireEquipment/SaveAllExcelImport`;
const REFRIGERANT_EXCEL_UPLOAD_API = `${BASEURL}/api/Refrigerant/RefrigerantExcelCheck`; // 冷媒
const REFRIGERANT_EXCEL_IMPORT_API = `${BASEURL}/api/Refrigerant/SaveAllExcelImport`;
const OTHERCOMPOUND_EXCEL_UPLOAD_API = `${BASEURL}/api/OtherCompound/OtherCompoundExcelCheck`; // 其他關注類
const OTHERCOMPOUND_EXCEL_IMPORT_API = `${BASEURL}/api/OtherCompound/SaveAllExcelImport`;

const KITCHEN_EXCEL_UPLOAD_API = `${BASEURL}/api/Kitchen/KitchenExcelCheck`; // 油氣燃料 // 製程
const KITCHEN_EXCEL_IMPORT_API = `${BASEURL}/api/Kitchen/SaveAllExcelImport`;
const VEHICLE_EXCEL_UPLOAD_API = `${BASEURL}/api/Vehicle/VehicleExcelCheck`; // 車輛 a
const VEHICLE_EXCEL_IMPORT_API = `${BASEURL}/api/Vehicle/SaveAllExcelImport`;
const GOSOLINE_EXCEL_UPLOAD_API = `${BASEURL}/api/GasolineEquipment/GasolineExcelCheck`; // 油氣燃料
const GOSOLINE_EXCEL_IMPORT_API = `${BASEURL}/api/GasolineEquipment/SaveAllExcelImport`;

const STEAM_EXCEL_UPLOAD_API = `${BASEURL}/api/SteamUsage/SteamUsageExcelCheck`; // 蒸氣
const STEAM_EXCEL_IMPORT_API = `${BASEURL}/api/SteamUsage/SaveAllExcelImport`;

const LIFECYCLE_EXCEL_IMPORT_API = `${BASEURL}/api/LifecycleAssmt/LifecycleAssmtExcelCheck`; // 生命週期
const LIFECYCLE_EXCEL_EXPORT_API = `${BASEURL}/api/LifecycleAssmt/LifecycleAssmtExcelExport`;

const COMMUTING_EXCEL_IMPORT_API = `${BASEURL}/api/EmployeeCommuting/EmployeeCommutingExcelSave`; // 3.3 員工通勤
const COMMUTING_EXCEL_EXPORT_API = `${BASEURL}/api/EmployeeCommuting/EmployeeCommutingExcelExport`;

const MATERIALTRANSPORT_EXCEL_UPLOAD_API = `${BASEURL}/api/MaterialTransport/MaterialTransportExcelCheck`; //3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
const MATERIALTRANSPORT_EXCEL_IMPORT_API = `${BASEURL}/api/MaterialTransport/SaveAllExcelImport`;

const PURCHASEDPRODUCT_EXCEL_UPLOAD_API = `${BASEURL}/api/PurchasedProduct/PurchasedProductExcelCheck`; //4.1 購買商品
const PURCHASEDPRODUCT_EXCEL_IMPORT_API = `${BASEURL}/api/PurchasedProduct/SaveAllExcelImport`;

// 佐證資料上傳
const UPLOAD_REFERENCE_API = `${BASEURL}/api/Upload`;
const UPLOAD_REPORT_API = `${BASEURL}/api/UploadUserFiles`;

// 工時人員類別新增、刪除、修改
const CREATEWORKERTYPE_API = `${BASEURL}/api/LCATypeParameter/Create`;
const DELETEWORKERTYPE_API = `${BASEURL}/api/LCATypeParameter/Delete`;
const UPDATEWORKERTYPE_API = `${BASEURL}/api/LCATypeParameter/Update`;

// 產出報表
const GETLCADATAREPORT_API = `${BASEURL}/api/LCA/OrganizationGenerateLCAdataReport`; // 產生盤查表
const GETLCADATAREPORT_III_API = `${BASEURL}/api/LCA/MOENVOrganizationGenerateLCAdataReport`; // 產生資策會盤查表
const GETLCALISTREPORT_API = `${BASEURL}/api/LCA/OrganizationGenerateLCAlistReport`; // 產生清冊
const GETLCAREPORT_API = `${BASEURL}/api/LCA/OrganizationGenerateLCAreport`; // 報告書
const GETLCARISKASSESSMENTREPORT_API = `${BASEURL}/api/LCA/GenerateRiskAssessmentReport`; // 風險評估
const GETALLREPORT_API = `${BASEURL}/api/LCA/OrganizationGenerateReport`; // 產生全部報表
const GETMERGEDLCALISTREPORT_API = `${BASEURL}/api/LCA/OrganizationGenerateMergedLCAlistReport`; // 合併清冊
const GETMERGEDLCAREPORT_API = `${BASEURL}/api/LCA/OrganizationGenerateMergedLCAreport`; // 合併報告書
const GETEXPORTREPORT_API = `${BASEURL}/api/LCA/GetExportReport`; // 合併報告書
const GETGHGREPORT_API = `${BASEURL2}/Report/GetGHGReport`; // 資策會報告書取得
const SETGHGREPORT_API = `${BASEURL2}/Report/SetGHGReport`; // 資策會報告書儲存
const SETLCACOMPLETE_API = `${BASEURL}/api/LCA/SetLCAComplete`; // 資策會報告書盤查完成
const SETLCAPROCESSING_API = `${BASEURL}/api/LCA/SetLCAProcessing`; // 資策會報告書盤查解鎖
const GETCOMPANYNAMEBYLCAID_API = `${BASEURL}/api/Company/GetCompanyNameByLCAID`; // 依盤查ID取得公司名稱

// export
const WORKHOUR_EXCEL_EXPORT_API = `${BASEURL}/api/WorkHour/WorkHourExcelExport`;
const FIREEQUIPMENT_EXCEL_EXPORT_API = `${BASEURL}/api/FireEquipment/FireEquipmentExcelExport`;
const REFRIGERANT_EXCEL_EXPORT_API = `${BASEURL}/api/Refrigerant/RefrigerantExcelExport`;
const OTHERCOMPOUND_EXCEL_EXPORT_API = `${BASEURL}/api/OtherCompound/OtherCompoundExcelExport`;
const KITCHEN_EXCEL_EXPORT_API = `${BASEURL}/api/Kitchen/KitchenExcelExport`;
const VEHICLE_EXCEL_EXPORT_API = `${BASEURL}/api/Vehicle/VehicleExcelExport`;
const GOSOLINE_EXCEL_EXPORT_API = `${BASEURL}/api/GasolineEquipment/GasolineEquipmentExcelExport`;
const POWERUSAGE_EXCEL_EXPORT_API = `${BASEURL}/api/PowerUsage/PowerUsageExcelExport`;
const STEAM_EXCEL_EXPORT_API = `${BASEURL}/api/SteamUsage/SteamUsageExcelExport`;
const MATERIALTRANSPORT_EXCEL_EXPORT_API = `${BASEURL}/api/MaterialTransport/MaterialTransportExcelExport`; //3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
const PURCHASEDPRODUCT_EXCEL_EXPORT_API = `${BASEURL}/api/PurchasedProduct/PurchasedProductExcelExport`; //4.1 購買商品
// III匯出
const KITCHEN_EXCEL_EXPORT_III_API = `${BASEURL}/api/Kitchen/MOENVKitchenExcelExport`;
const VEHICLE_EXCEL_EXPORT_III_API = `${BASEURL}/api/Vehicle/MOENVVehicleExcelExport`;
const WORKHOUR_EXCEL_EXPORT_III_API = `${BASEURL}/api/WorkHour/MOENVWorkHourExcelExport`;
const REFRIGERANT_EXCEL_EXPORT_III_API = `${BASEURL}/api/Refrigerant/MOENVRefrigerantExcelExport`;
const FIREEQUIPMENT_EXCEL_EXPORT_III_API = `${BASEURL}/api/Refrigerant/MOENVFireEquipmentExcelExport`;
const POWERUSAGE_EXCEL_EXPORT_III_API = `${BASEURL}/api/PowerUsage/MOENVPowerUsageExcelExport`;
const STEAM_EXCEL_EXPORT_III_API = `${BASEURL}/api/SteamUsage/MOENVSteamUsageExcelExport`;
const OTHERCOMPOUND_EXCEL_EXPORT_III_API = `${BASEURL}/api/OtherCompound/MOENVOtherCompoundExcelExport`;
// 圖表
const GETCHARTFADLIST_API = `${BASEURL}/api/Chart/GetFabSelectOptions`; // 工廠列表
const GETCHARTS1S3TOTAL_API = `${BASEURL}/api/Chart/GetTotalDataS1S3`; //取得總量S1S3
const GETCHARTSCOPEFORS1S3_API = `${BASEURL}/api/Chart/GetScopeDataS1S3`; //直接間接其他排放S1S3
const GETCHARTPROCESSFORS1S3_API = `${BASEURL}/api/Chart/GetProcessEmissionS1S3`; //燃燒工業製程人為逸散S1S3
const GETCHARTFUELFORS1S3_API = `${BASEURL}/api/Chart/GetFuelChartS1S3`; //燃燒-固定源與移動源分析S1S3
const GETCHARTDIRECTFUGITIVEFORS1S3_API = `${BASEURL}/api/Chart/GetRefFireWorkHourS1S3`; //人為逸散-工時 冷媒 消防分析S1S3
const GETCHARTELECSTEAMFORS1S3_API = `${BASEURL}/api/Chart/GetElecSteamS1S3`; //用電量蒸氣量S1S3
const GETCHARTRISKFORS1S3_API = `${BASEURL}/api/Chart/GetRiskS3`; //S3分析 其他排放
const GETCHARTS1S2TOTAL_API = `${BASEURL}//api/Chart/GetTotalDataS1S2`; // 取得總量S1S2
const GETCHARTSCOPEFORS1S2_API = `${BASEURL}/api/Chart/GetScopeDataS1S2`; //直接間接其他排放S1S2

const GETELECNUMBER_API = `${BASEURL}/api/PowerUsage/GetElecNumbers`; // 取得電表號
const UPDATEELECNUMBER_API = `${BASEURL}/api/PowerUsage/UpdateElecNumber`; // 更新電表號
const CREATEELECNUMBER_API = `${BASEURL}/api/PowerUsage/CreateElecNumber`; // 新增電表號
const DELETEELECNUMBER_API = `${BASEURL}/api/PowerUsage/DeleteElecNumber`; // 刪除電表號

const GETSTEAMNUMBER_API = `${BASEURL}/api/SteamUsage/GetSteamNumbers`; // 取得蒸氣號
const UPDATESTEAMNUMBER_API = `${BASEURL}/api/SteamUsage/UpdateSteamNumber`; // 更新蒸氣號
const CREATESTEAMNUMBER_API = `${BASEURL}/api/SteamUsage/CreateSteamNumber`; // 新增蒸氣號
const DELETESTEAMNUMBER_API = `${BASEURL}/api/SteamUsage/DeleteSteamNumbeR`; // 刪除蒸氣號

// 查驗組織型
const VERIFYORGANIZATION_API = `${BASEURL}/api/LCA/OrganizationProcessWaiting`;
//SBTI
const GETSBTILIST_API = `${BASEURL}/api/PowerUsage/GetPowerUsages`;
const GETSBTICHART_API = `${BASEURL}/api/PowerUsage/GetSBTiGraph`;
const GETSBTIREQUEST_API = `${BASEURL2}/SBTi/GetSBTiRequest`; // 取得上次減碳路徑規劃輸入請求
const GETSBTIEMISSIONCOMBINELIST_API = `${BASEURL2}/SBTi/GetEmissionCombineList`; // 取得方案組合
const GETSBTIEMISSIONPLANLIST_API = `${BASEURL2}/SBTi/GetEmissionPlanList`; // 取得淨零方案設備
const SETSBTIEMISSIONPLAN_API = `${BASEURL2}/SBTi/SetEmissionPlan`; // 儲存淨零方案設備
const DELETESBTIEMISSIONPLAN_API = `${BASEURL2}/SBTi/DelEmissionPlan`; // 刪除淨零方案設備
const SETSBTIEMISSIONCOMBINE_API = `${BASEURL2}/SBTi/SetEmissionCombine`; // 儲存方案組合
const DELETESBTIEMISSIONCOMBINE_API = `${BASEURL2}/SBTi/DelEmissionCombine`; // 刪除方案組合

// 填寫人
const WORKHOUR_SHEET_API = `${BASEURL}/api/WorkHour/SaveSheetHeader`;
const POWERUSAGE_SHEET_API = `${BASEURL}/api/PowerUsage/SaveSheetHeader`;
const VEHICLE_SHEET_API = `${BASEURL}/api/Vehicle/SaveSheetHeader`;
const KITCHEN_SHEET_API = `${BASEURL}/api/Kitchen/SaveSheetHeader`;
const FIREEQUIPMENT_SHEET_API = `${BASEURL}/api/FireEquipment/SaveSheetHeader`;
const REFRIGERANT_SHEET_API = `${BASEURL}/api/Refrigerant/SaveSheetHeader`;
const STEAM_SHEET_API = `${BASEURL}/api/SteamUsage/SaveSheetHeader`;
const OTHERCOMPOUND_SHEET_API = `${BASEURL}/api/OtherCompound/SaveSheetHeader`;
const LIFECYCLE_SHEET_API = `${BASEURL}/api/RiskAssmtLayout/SaveSheetHeader`;

// 顯著性分數
const UPDATE_ASSMTSCORE = `${BASEURL}/api/SignificanceAssmt/UpdateGradingScore`;

//內部查證
const GETVERIFICATIONDETAIL_API = `${BASEURL}/api/Verification/GetVerificationDetail`;
const SETVERIFICATIONDETAIL_API = `${BASEURL}/api/Verification/SetVerificationDetail`;
const GETUSERROLES_API = `${BASEURL}/api/Verification/GetUserRoles`;
const GETVERIFICATIONMAIN_API = `${BASEURL}/api/Verification/GetVerificationMain`;
const GETVERIFICATIONTEMPLATELIST_API = `${BASEURL}/api/Verification/GetVerificationTemplateList`;
const GETVERIFICATIONTEMPLATEINFO_API = `${BASEURL}/api/Verification/GetVerificationTemplateInfo`;
const GETVERIFICATIONTEMPLATEINFOBYID_API = `${BASEURL}/api/Verification/GetVerificationTemplateTitleInfo`;
const SETVERIFICATIONTEMPLATE_API = `${BASEURL}/api/Verification/SetVerificationTemplate`;
const GETVERIFICATIONGROUP_API = `${BASEURL}/api/Verification/GetVerificationGroup`;
const GETVERIFICATIONTITLE_API = `${BASEURL}/api/Verification/GetVerificationTitle`;
const GETVERIFICATIONTITLEFILE_API = `${BASEURL}/api/Verification/ExportVerificationFile`;
const SETUSERROLES_API = `${BASEURL}/api/Verification/SetUserRoles`;
const GETALLUSERROLES_API = `${BASEURL}/api/User/GetAllMyCompanyUser`;
const UPLOAD_VERIFICATIONFILE_API = `${BASEURL}/api/Verification/UploadVerificationFile`;
const CLOSE_VERIFICATION_API = `${BASEURL}/api/Verification/Close`;
const ADD_VERIFICATIONMAIN = `${BASEURL}/api/Verification/AddVerificationMain`;
const GET_VERIFICATIONMAINITEM = `${BASEURL}/api//Verification/GetVerificationMainItem`;
const CLONE_TEMPLATE = `${BASEURL}/api/Verification/CloneTemplate`;
const SET_VERIFICATIONTITLE = `${BASEURL}/api/Verification/SetVerificationTitle`;
const GET_SYSTEMTEMPLATELIST = `${BASEURL}/api/Verification/GetSystemTemplateList`;
const SET_VERIFICATIONMAIN = `${BASEURL}/api/Verification/SetVerificationMain`;
const DELETE_VERIFICATIONTENPLATECLAUSE_API = `${BASEURL}/api/Verification/DelVerificationTitle`;

//溫室氣體清冊
const UPLOAD_GREENWORKFILE_API = `${BASEURL}/api/LCA/UpLoadGreenhouseGasInventoryWorkFile`; // 溫室氣體規範上傳檔案

// 引用
const COPY_LIFECYCLE_API = `${BASEURL}/api/LifecycleAssmt/Quote`; // 一般生命週期
const COPY_UPSTREAMTRANSPORT_API = `${BASEURL}/api/MaterialTransport/QuoteMaterialTransport`; // 3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
const COPY_PURCHASEDPRODUCT_API = `${BASEURL}/api/PurchasedProduct/QuotePurchasedProduct`; // 購買商品
const COPY_COMMUTING_API = `${BASEURL}/api/EmployeeCommuting/Quote`; // 員工通勤
const COPY_OTHER_API = `${BASEURL}/api/OtherCompound/Quote`; // 其他
const COPY_VEHICLE_API = `${BASEURL}/api/Vehicle/Quote`; // 移動
const COPY_KITCHEN_API = `${BASEURL}/api/Kitchen/Quote`; // 固定燃燒源、工業
const COPY_REFRIGERANT_API = `${BASEURL}/api/Refrigerant/Quote`; // 冷媒
const COPY_FIREEQUIPMENT_API = `${BASEURL}/api/FireEquipment/Quote`; // 消防
const COPY_STEAM_API = `${BASEURL}/api/SteamUsage/Quote`; // 蒸氣
const COPY_POWERUSAGE_API = `${BASEURL}/api/PowerUsage/Quote`; // 用電量
const COPY_WORKHOUR_API = `${BASEURL}/api/WorkHour/Quote`; // 工時

// 模板下載
const DOWNLOADSAMPLE_OTHER_API = `${BASEURL}/api/OtherCompound/GetOtherCompoundExcelTemplate`; // 其他
const DOWNLOADSAMPLE_VEHICLE_API = `${BASEURL}/api/Vehicle/GetVehicleExcelTemplate`; // 移動
const DOWNLOADSAMPLE_KITCHEN_API = `${BASEURL}/api/Kitchen/GetKitchenExcelTemplate?WarmGasType=`; // 固定燃燒源、工業
const DOWNLOADSAMPLE_REFRIGERANT_API = `${BASEURL}/api/Refrigerant/GetRefrigerantExcelTemplate`; // 冷媒
const DOWNLOADSAMPLE_FIREEQUIPMENT_API = `${BASEURL}/api/FireEquipment/GetFireEquipmentExcelTemplate`; // 消防
const DOWNLOADSAMPLE_STEAM_API = `${BASEURL}/api/SteamUsage/GetSteamUsageExcelTemplate`; // 蒸氣
const DOWNLOADSAMPLE_POWERUSAGE_API = `${BASEURL}/api/PowerUsage/GetPowerUsageExcelTemplate?Type=`; // 用電量
const DOWNLOADSAMPLE_WORKHOUR_API = `${BASEURL}/api/WorkHour/GetWorkHourExcelTemplate`; // 工時
const DOWNLOADSAMPLE_LIFECYCLE_API = `${BASEURL}/api/LifecycleAssmt/GetLifecycleAssmtExcelTemplate`; //
const DOWNLOADSAMPLE_COMMUTING_API = `${BASEURL}/api/EmployeeCommuting/GetEmployeeCommutingExcelTemplate?Type=`; // 通勤
const DOWNLOADSAMPLE_MATERIALTRANSPORT_API = `${BASEURL}/api/MaterialTransport/GetMaterialTransportExcelTemplate`; //3.1 上游運輸 3.2.1下游運輸 3.2.2廢棄物運輸
const DOWNLOADSAMPLE_PURCHASEDPRODUCT_API = `${BASEURL}/api/PurchasedProduct/GetPurchasedProductExcelTemplate`; //4.1 購買商品
// 資策會模板下載
const DOWNLOADSAMPLE_KITCHEN_III_API = `${BASEURL}/api/Kitchen/MOENVGetKitchenExcelTemplate?WarmGasType=`; // 固定、製程
const DOWNLOADSAMPLE_VEHICLE_III_API = `${BASEURL}/api/Vehicle/MOENVGetVehicleExcelTemplate`; // 移動
const DOWNLOADSAMPLE_WORKHOUR_III_API = `${BASEURL}/api/WorkHour/MOENVGetWorkHourExcelTemplate`; // 工時
const DOWNLOADSAMPLE_REFRIGERANT_III_API = `${BASEURL}/api/Refrigerant/MOENVGetRefrigerantExcelTemplate`; // 冷媒
const DOWNLOADSAMPLE_FIREEQUIPMENT_III_API = `${BASEURL}/api/FireEquipment/MOENVGetFireEquipmentExcelTemplate`; // 消防
const DOWNLOADSAMPLE_STEAM_III_API = `${BASEURL}/api/SteamUsage/MOENVGetSteamUsageExcelTemplate`; // 蒸氣
const DOWNLOADSAMPLE_POWERUSAGE_III_API = `${BASEURL}/api/PowerUsage/MOENVGetPowerUsageExcelTemplate?Type=`; // 用電量
const DOWNLOADSAMPLE_OTHERCOMPOUND_III_API = `${BASEURL}/api/OtherCompound/MOENVGetOtherCompoundExcelTemplate`; // 用電量

// 取得總量
const GET_STAGETOTAL_API = `${BASEURL}/api/LCA/StageKgCO2`;

// 資策會
const GET_III_SYSTEMTYPE = `${BASEURL}/api/SystemSettingMenu/GetSettingMenu`;
const CREATE_III_SYSTEMTYPE = `${BASEURL}/api/SystemSettingMenu/CreateSettingMenu`;
const UPDATE_III_SYSTEMTYPE = `${BASEURL}/api/SystemSettingMenu/UpdateSettingMenu`;
const DELETE_III_SYSTEMTYPE = `${BASEURL}/api/SystemSettingMenu/DeleteSystemMenu`;

// - 年自訂係數
const GET_COMPANY_PARA_API = `${BASEURL}/api/Parameter/GetCompanyParameter`;
const CREATE_COMPANY_PARA_API = `${BASEURL}/api/Parameter/CreateCompanyYearlyParameter`;
const UPDATE_COMPANY_PARA_API = `${BASEURL}/api/Parameter/UpdateCompanyYearlyParameter`;
const DELETE_COMPANY_PARA_API = `${BASEURL}/api/Parameter/DeleteCompanyYearlyParameter`;

// 輸入電力 C2.一般用電 B.3.2.a 使用的API
const CREATE_POWERUSAGES_PARA_API = `${BASEURL}/api/Parameter/CreateYearlyEletricParameter`;
const UPDATE_POWERUSAGES_PARA_API = `${BASEURL}/api/Parameter/UpdateYearlyEletricParameter`;
const DELETE_POWERUSAGES_PARA_API = `${BASEURL}/api/Parameter/DeleteYearlyEletricParameter`;

// - 地區
const CREATE_AREA_PARA_API = `${BASEURL}/api/Parameter/CreateCompanyYearlyParameterArea`;
const UPDATE_AREA_PARA_API = `${BASEURL}/api/Parameter/UpdateCompanyYearlyParameterArea`;
const DELETE_AREA_PARA_API = `${BASEURL}/api/Parameter/DeleteCompanyYearlyParameterArea`;

// - 取得指定汙染源
const GET_SOURCE_PARA_BYID_API = `${BASEURL}/api/Parameter/GetCompanyParameterType`;
const GET_SOURCE_PARA_BYID = `${BASEURL}/api/Parameter/GetCompanyYearlyParameter`;

// - 燃料源
const GET_SOURCE_PARA_API = `${BASEURL}/api/Parameter/GetCompanyYearlyParameterType`;
const CREATE_SOURCE_PARA_API = `${BASEURL}/api/Parameter/CreateCompanyYearlyParameterType`;
const UPDATE_SOURCE_PARA_API = `${BASEURL}/api/Parameter/UpdateCompanyYearlyParameterType`;
const DELETE_SOURCE_PARA_API = `${BASEURL}/api/Parameter/DeleteCompanyYearlyParameterType`;

// - 非年自訂係數
const GET_COMPANY_NONYEAR_PARA_API = `${BASEURL}/api/Parameter/GetCompanyNonYearlyParameters`;
const CREATE_COMPANY_NONYEAR_PARA_API = `${BASEURL}/api/Parameter/CreateCompanyNonYearlyParameter`;
const UPDATE_COMPANY_NONYEAR_PARA_API = `${BASEURL}/api/Parameter/UpdateCompanyNonYearlyParameter`;
const DELETE_COMPANY_NONYEAR_PARA_API = `${BASEURL}/api/Parameter/DeleteCompanyNonYearlyParameter`;

// - SimaPro自訂係數
const CREATE_COMPANY_SIMAPRO_CATEGORY_API = `${BASEURL}/api/Parameter/CreateCompanySimaproCategory`;
const UPDATE_COMPANY_SIMAPRO_CATEGORY_API = `${BASEURL}/api/Parameter/UpdateCompanySimaproCategory`;
const DELETE_COMPANY_SIMAPRO_CATEGORY_API = `${BASEURL}/api/Parameter/DeleteCompanySimaproCategory`;

const CREATE_COMPANY_SIMAPRO_TYPE_API = `${BASEURL}/api/Parameter/CreateCompanySimaproType`;
const UPDATE_COMPANY_SIMAPRO_TYPE_API = `${BASEURL}/api/Parameter/UpdateCompanySimaproType`;
const DELETE_COMPANY_SIMAPRO_TYPE_API = `${BASEURL}/api/Parameter/DeleteCompanySimaproType`;

const CREATE_COMPANY_SIMAPRO_PARA_API = `${BASEURL}/api/Parameter/CreateCompanySimaproParameter`;
const UPDATE_COMPANY_SIMAPRO_PARA_API = `${BASEURL}/api/Parameter/UpdateCompanySimaproParameter`;
const DELETE_COMPANY_SIMAPRO_PARA_API = `${BASEURL}/api/Parameter/DeleteCompanySimaproParameter`;

// 資料傳遞
const UPLOAD_FABIMG = `${BASEURL}/api/UploadImg/`;
const UPLOAD_REPORTIMG = `${BASEURL}/api/UploadOtherImg/`;
const Delete_CompanySourceRefereneceFileLinks = `${BASEURL}/api/Parameter/DeleteCompanySourceRefereneceFileLinks`;

// 中台
const GET_MIDDLE_COMPANY_SEARCH = `${BASEURL}/api/MiddleAdmin/GetSelectOptions`;
const GET_MIDDLE_REPORT = `${BASEURL}/api/MiddleAdmin/GetReportC1C6`;

// 進階設定
const GET_SBTISETTING_API = `${BASEURL}/api/SBTI/GetLCAsSetting`;
const POST_SBTISETTING_API = `${BASEURL}/api/SBTI/SetLCAsSetting`;
const GET_CARBON_API = `${BASEURL}/api/SBTI/GetSBTIBasicInfo`;
const GET_CARBONSTRENGTH_API = `${BASEURL}/api/SBTI/GetSBTIStrength`;
const POST_SBTICALCULATE_API = `${BASEURL}/api/SBTI/Calculate`;

// 專案管理
const GET_PROJECTLIST_API = `${BASEURL}/api/Project/GetLCAProjectList`; // ?LCAID=616
const POST_PROJECTACTIVE_API = `${BASEURL}/api/Project/SetLCAProjectActive`;
const GET_PROJECT_API = `${BASEURL}/api/Project/GetLCAProject`; // ?LCAProjectKey=5
const POST_PROJECT_API = `${BASEURL}/api/Project/SetLCAProjects`;
const POST_PROJECTAUTH_API = `${BASEURL}/api/Project/SetUserAuth`;
const GET_FUNCTIONLIST_API = `${BASEURL}/api/Project/GetLCAFunctionList`; // ?LCAID=616
const POST_FUNCTION_API = `${BASEURL}/api/Project/SetLCAFunction`;
const DELETE_PROJECTUSER_API = `${BASEURL}/api/Project/DelCAProjectUser`; // ?LCAProjectUserKey=1
const POST_USERAUTH_API = `${BASEURL}/api/Project/SetInitFunction`;
const GET_FUNAUTHCLASS_API = `${BASEURL}/api/Project/GetProjectClass`;

const GET_PROJECTSTATUS_API = `${BASEURL}/api/Project/GetScheduleStatus`;
const GET_PROJECTSCHEDULE_API = `${BASEURL}/api/Project/GetScheduleList`; //?LCAID=589
const GET_PROJECTPROGRESS_API = `${BASEURL}/api/LCA/GetLCAsProgress`;
const POST_PROJECTSTATUS_API = `${BASEURL}/api/Project/SetSchedule`;

const GET_AUTHFUNCTION_API = `${BASEURL}/api/Project/GetFunctionAuthorList`;
const GET_AUTHPROJECT_API = `${BASEURL}/api/Project/GetProjectAuthorList`; //?LCAID=589
const GET_FUNGROUP_API = `${BASEURL}/api/Project/GetFunctionGroup`;

const GET_SAMEFABUSER_API = `${BASEURL}/api/Project/GetFabUsers`; //?LCAID=611
const POST_NEWADMIN_API = `${BASEURL}/api/User/ModifyCompanyAdmin`; // ?UserId=0e090775-1807-4f14-927c-f8d95d35e088

const GET_ACTIONLOG_API = `${BASEURL}/api/ActionLog/ProjectLog`;

//一鍵設定系列
const ONE_KEY_OPENPROJECT = `${BASEURL}/api/Project/OneKeyOpenProject`;
const ONE_KEY_COMPLETEPROJECT = `${BASEURL}/api/Project/OneKeyCompleteProject`;

// 減碳專案
const POST_SBTISCOPE1PRO_API = `${BASEURL}/api/SBTI/Scope1Save`; // LCAID/ProId
const POST_SBTISCOPE2PRO_API = `${BASEURL}/api/SBTI/Scope2Save`; // LCAID/ProId
const GET_SBTIPRO_API = `${BASEURL}/api/SBTI/GetScopesInfo`; // LCAID

// 匯出查詢平台
const GET_MIDDLE_COMPARE_API = `${BASEURL}/api/MiddleAdmin/GetReportC1C6Excel`; //?FabIds=544&StartYear=2021&EndYear=2029

// 公告欄
const GET_PUBLICMESSAGE_API = `${BASEURL}/api/PublicMessage`;

// 淨零路淨匯入匯出
const POST_EXPORTSBTI_API = `${BASEURL2}/SBTi/ExportSBTi`;
const POST_IMPORTSBTI_API = `${BASEURL2}/SBTi/ImportSBTi`;
// 教學影片連結
const GET_YOUTUBEVIDEO_API = `${BASEURL2}/Video/videos`;

// 顧問查詢係數匯入
const GET_ADVISORLIST_API = `${BASEURL2}/ParameterConsultant/GetParameterConsultantAll`; // LCAID/GHGIntemNo
const POST_ADVISOR_API = `${BASEURL2}/ParameterConsultant/ActivateParameterConsultantByIDs`;

export {
  BASEURL,
  VERIFY_API_URL,
  LOGIN_API,
  LOGOUT_API,
  LCALIST_API,
  REGISTER_API,
  VERIFY_API,
  CREATEORGANIZATIONLCA_API,
  UPDATELCADETAIL_API,
  GETCERTIFICATIONCOMPANY_API,
  GETFAB_API,
  GETARVERSION_API,
  GETLCADETAIL_API,
  RECOVERY_API,
  CONFIRMRESETPASSWORD_API,
  RECONFIRMRESETPASSWORD_API,
  CHANGEPASSWORD_API,
  CHANGELANG_API,
  CULTUERSET_API,
  //公司管理相關
  ENTERPRISEINFO_API,
  ENTERPRISELIST_API,
  ENTERPRISELIST_ADD_API,
  ENTERPRISELIST_DEL_API,
  ENTERPRISELIST_SETTING_API,
  //係數庫管理
  GETPARAMETERCATEGORY_API,
  GETTYPEWITHOUTPARAMETER_API,
  GETPARAMETERTYPE_API,
  GETPARAMETERBYENCODING_API,
  GETSIMAPROPARAMETER_API,
  //顯著性分析
  ADDASSMTFACTOR_API,
  DEL_ORISIGNIFICANCE_API,
  DEL_GRPSIGNIFICANCE_API,
  EDITSIGNIFICANCEAAAMT_API,
  DELETEASSMTFACTOR_API,
  GETASSMTFACTOR_API,
  GETSIGNIFICANC_API,
  Get_All_CycleStage,
  Get_Transport_CycleStage,
  Get_Employee_CycleStage,
  SaveOrig_API,
  SaveGroup_API,

  //使用者相關
  ADDUSER_API,
  USERLIST_API,
  USERINFO_API,
  DELUSER_API,
  MODIFYUSER_API,
  CHANGEPWD_API,
  // 各類別資料取得
  GETPARAMETER_API,
  GETLIFECYCLE_API,
  GETCOMMUTING_API,
  GETWORKHOUR_API,
  GETPOWERUSAGE_API,
  GETVEHICLE_API,
  GETKITCHEN_API,
  GETKITCHENFORCOPY_API,
  GETFIREEQUIPMENT_API,
  GETREFRIGERANT_API,
  GETSTEAMUSAGE_API,
  GETOTHERCOMPOUND_API,
  GETPURCHASEDPRODUCT_API,
  GETUPSTREAMTRANSPORT_API,
  // 各類別資料POST
  POSTREFRIGERANT_API,
  POSTFIREEQUIPMENT_API,
  POSTKITCHEN_API,
  POSTVEHICLE_API,
  POSTOTHERCOMPOUND_API,
  POSTLIFECYCLE_API,
  POSTCOMMUTING_API,
  POSTPURCHASEDPRODUCT_API,
  POSTUPSTREAMTRANSPORT_API,
  // update
  UPDATEWORKHOUR_API,
  UPDATEPOWERUSAGE_API,
  UPDATEGREENPOWERUSAGE_API,
  UPDATESTEAMUSAGE_API,
  // DELETE
  DELETEVEHICLE_API,
  DELETEREFRIGERANT_API,
  DELETEFIREEQUIPMENT_API,
  DELETEKITCHEN_API,
  DELETEOTHERCOMPOUND_API,
  DELETELIFECYCLE_API,
  DELETEPURCHASEDPRODUCT_API,
  DELETEUPSTREAMTRANSPORT_API,
  DELETECOMMUTING_API,
  GETALLCYCLESTAGE_API,
  //排放源匯入 Excel
  WORKHOUR_EXCEL_UPLOAD_API,
  WORKHOUR_EXCEL_IMPORT_API,
  POWERUSAGE_EXCEL_UPLOAD_API,
  POWERUSAGE_EXCEL_IMPORT_API,
  FIREEQUIPMENT_EXCEL_UPLOAD_API,
  FIREEQUIPMENT_EXCEL_IMPORT_API,
  REFRIGERANT_EXCEL_UPLOAD_API,
  REFRIGERANT_EXCEL_IMPORT_API,
  OTHERCOMPOUND_EXCEL_UPLOAD_API,
  OTHERCOMPOUND_EXCEL_IMPORT_API,
  KITCHEN_EXCEL_UPLOAD_API,
  KITCHEN_EXCEL_IMPORT_API,
  VEHICLE_EXCEL_UPLOAD_API,
  VEHICLE_EXCEL_IMPORT_API,
  GOSOLINE_EXCEL_UPLOAD_API,
  GOSOLINE_EXCEL_IMPORT_API,
  STEAM_EXCEL_UPLOAD_API,
  STEAM_EXCEL_IMPORT_API,
  UPLOAD_REFERENCE_API,
  UPLOAD_REPORT_API,
  LIFECYCLE_EXCEL_IMPORT_API,
  LIFECYCLE_EXCEL_EXPORT_API,
  COMMUTING_EXCEL_IMPORT_API,
  COMMUTING_EXCEL_EXPORT_API,
  MATERIALTRANSPORT_EXCEL_UPLOAD_API,
  MATERIALTRANSPORT_EXCEL_IMPORT_API,
  PURCHASEDPRODUCT_EXCEL_UPLOAD_API,
  PURCHASEDPRODUCT_EXCEL_IMPORT_API,

  // 工時人員類別新增、刪除、修改
  CREATEWORKERTYPE_API,
  DELETEWORKERTYPE_API,
  UPDATEWORKERTYPE_API,

  // 產出報表
  GETLCADATAREPORT_API,
  GETLCADATAREPORT_III_API,
  GETLCALISTREPORT_API,
  GETLCAREPORT_API,
  GETLCARISKASSESSMENTREPORT_API,
  GETALLREPORT_API,
  GETMERGEDLCALISTREPORT_API,
  GETMERGEDLCAREPORT_API,
  GETEXPORTREPORT_API,
  GETGHGREPORT_API,
  SETGHGREPORT_API,
  SETLCACOMPLETE_API,
  SETLCAPROCESSING_API,
  GETCOMPANYNAMEBYLCAID_API,

  // EXPORTS
  WORKHOUR_EXCEL_EXPORT_API,
  FIREEQUIPMENT_EXCEL_EXPORT_API,
  REFRIGERANT_EXCEL_EXPORT_API,
  OTHERCOMPOUND_EXCEL_EXPORT_API,
  KITCHEN_EXCEL_EXPORT_API,
  VEHICLE_EXCEL_EXPORT_API,
  GOSOLINE_EXCEL_EXPORT_API,
  POWERUSAGE_EXCEL_EXPORT_API,
  STEAM_EXCEL_EXPORT_API,
  MATERIALTRANSPORT_EXCEL_EXPORT_API,
  PURCHASEDPRODUCT_EXCEL_EXPORT_API,
  // III匯出
  KITCHEN_EXCEL_EXPORT_III_API,
  VEHICLE_EXCEL_EXPORT_III_API,
  WORKHOUR_EXCEL_EXPORT_III_API,
  REFRIGERANT_EXCEL_EXPORT_III_API,
  FIREEQUIPMENT_EXCEL_EXPORT_III_API,
  POWERUSAGE_EXCEL_EXPORT_III_API,
  STEAM_EXCEL_EXPORT_III_API,
  OTHERCOMPOUND_EXCEL_EXPORT_III_API,
  // 圖表
  GETCHARTELECSTEAMFORS1S3_API,
  GETCHARTFADLIST_API,
  GETCHARTFUELFORS1S3_API,
  GETCHARTDIRECTFUGITIVEFORS1S3_API,
  GETCHARTPROCESSFORS1S3_API,
  GETCHARTRISKFORS1S3_API,
  GETCHARTS1S2TOTAL_API,
  GETCHARTS1S3TOTAL_API,
  GETCHARTSCOPEFORS1S2_API,
  GETCHARTSCOPEFORS1S3_API,
  // 電表
  GETELECNUMBER_API,
  UPDATEELECNUMBER_API,
  CREATEELECNUMBER_API,
  DELETEELECNUMBER_API,

  // 蒸氣
  GETSTEAMNUMBER_API,
  UPDATESTEAMNUMBER_API,
  CREATESTEAMNUMBER_API,
  DELETESTEAMNUMBER_API,
  VERIFYORGANIZATION_API,

  //SBTI
  GETSBTILIST_API,
  GETSBTICHART_API,
  GETSBTIREQUEST_API,
  GETSBTIEMISSIONCOMBINELIST_API,
  GETSBTIEMISSIONPLANLIST_API,
  SETSBTIEMISSIONPLAN_API,
  DELETESBTIEMISSIONPLAN_API,
  SETSBTIEMISSIONCOMBINE_API,
  DELETESBTIEMISSIONCOMBINE_API,
  // 填寫人
  WORKHOUR_SHEET_API,
  POWERUSAGE_SHEET_API,
  VEHICLE_SHEET_API,
  KITCHEN_SHEET_API,
  FIREEQUIPMENT_SHEET_API,
  REFRIGERANT_SHEET_API,
  STEAM_SHEET_API,
  OTHERCOMPOUND_SHEET_API,
  LIFECYCLE_SHEET_API,
  // 顯著性分數
  UPDATE_ASSMTSCORE,
  //內部查證
  GETVERIFICATIONDETAIL_API,
  GETUSERROLES_API,
  GETVERIFICATIONMAIN_API,
  GETVERIFICATIONTEMPLATELIST_API,
  GETVERIFICATIONGROUP_API,
  GETVERIFICATIONTITLE_API,
  SETVERIFICATIONDETAIL_API,
  SETUSERROLES_API,
  GETALLUSERROLES_API,
  GETVERIFICATIONTITLEFILE_API,
  UPLOAD_GREENWORKFILE_API,
  GET_VERIFICATIONMAINITEM,
  ADD_VERIFICATIONMAIN,
  SETVERIFICATIONTEMPLATE_API,
  CLONE_TEMPLATE,
  SET_VERIFICATIONTITLE,
  GETVERIFICATIONTEMPLATEINFO_API,
  GETVERIFICATIONTEMPLATEINFOBYID_API,
  GET_SYSTEMTEMPLATELIST,
  SET_VERIFICATIONMAIN,
  DELETE_VERIFICATIONTENPLATECLAUSE_API,

  // 引用
  COPY_LIFECYCLE_API,
  COPY_PURCHASEDPRODUCT_API,
  COPY_UPSTREAMTRANSPORT_API,
  COPY_COMMUTING_API,
  COPY_OTHER_API,
  COPY_VEHICLE_API,
  COPY_KITCHEN_API,
  COPY_REFRIGERANT_API,
  COPY_FIREEQUIPMENT_API,
  COPY_STEAM_API,
  COPY_POWERUSAGE_API,
  COPY_WORKHOUR_API,
  UPLOAD_VERIFICATIONFILE_API,
  CLOSE_VERIFICATION_API,

  // 模板下載
  DOWNLOADSAMPLE_OTHER_API,
  DOWNLOADSAMPLE_VEHICLE_API,
  DOWNLOADSAMPLE_KITCHEN_API,
  DOWNLOADSAMPLE_REFRIGERANT_API,
  DOWNLOADSAMPLE_FIREEQUIPMENT_API,
  DOWNLOADSAMPLE_STEAM_API,
  DOWNLOADSAMPLE_POWERUSAGE_API,
  DOWNLOADSAMPLE_WORKHOUR_API,
  DOWNLOADSAMPLE_LIFECYCLE_API,
  DOWNLOADSAMPLE_COMMUTING_API,
  DOWNLOADSAMPLE_MATERIALTRANSPORT_API,
  DOWNLOADSAMPLE_PURCHASEDPRODUCT_API,
  // 資策會模板下載
  DOWNLOADSAMPLE_KITCHEN_III_API,
  DOWNLOADSAMPLE_VEHICLE_III_API,
  DOWNLOADSAMPLE_WORKHOUR_III_API,
  DOWNLOADSAMPLE_REFRIGERANT_III_API,
  DOWNLOADSAMPLE_FIREEQUIPMENT_III_API,
  DOWNLOADSAMPLE_STEAM_III_API,
  DOWNLOADSAMPLE_POWERUSAGE_III_API,
  DOWNLOADSAMPLE_OTHERCOMPOUND_III_API,

  // 取得總量
  GET_STAGETOTAL_API,

  // 資策會
  GET_III_SYSTEMTYPE,
  CREATE_III_SYSTEMTYPE,
  UPDATE_III_SYSTEMTYPE,
  DELETE_III_SYSTEMTYPE,

  //指定汙染源
  GET_SOURCE_PARA_BYID_API,
  GET_SOURCE_PARA_BYID,

  // - 年自訂係數
  GET_SOURCE_PARA_API,
  GET_COMPANY_PARA_API,
  CREATE_COMPANY_PARA_API,
  UPDATE_COMPANY_PARA_API,
  DELETE_COMPANY_PARA_API,
  CREATE_AREA_PARA_API,
  UPDATE_AREA_PARA_API,
  DELETE_AREA_PARA_API,
  CREATE_SOURCE_PARA_API,
  UPDATE_SOURCE_PARA_API,
  DELETE_SOURCE_PARA_API,

  // 輸入電力 C2.一般用電 B.3.2.a 使用的API
  CREATE_POWERUSAGES_PARA_API,
  UPDATE_POWERUSAGES_PARA_API,
  DELETE_POWERUSAGES_PARA_API,

  // - 非年自訂係數
  GET_COMPANY_NONYEAR_PARA_API,
  CREATE_COMPANY_NONYEAR_PARA_API,
  UPDATE_COMPANY_NONYEAR_PARA_API,
  DELETE_COMPANY_NONYEAR_PARA_API,

  // - SimaPro自訂係數
  CREATE_COMPANY_SIMAPRO_CATEGORY_API,
  UPDATE_COMPANY_SIMAPRO_CATEGORY_API,
  DELETE_COMPANY_SIMAPRO_CATEGORY_API,
  CREATE_COMPANY_SIMAPRO_TYPE_API,
  UPDATE_COMPANY_SIMAPRO_TYPE_API,
  DELETE_COMPANY_SIMAPRO_TYPE_API,
  CREATE_COMPANY_SIMAPRO_PARA_API,
  UPDATE_COMPANY_SIMAPRO_PARA_API,
  DELETE_COMPANY_SIMAPRO_PARA_API,

  // 資料傳遞
  UPLOAD_FABIMG,
  UPLOAD_REPORTIMG,
  Delete_CompanySourceRefereneceFileLinks,

  // 中台
  GET_MIDDLE_COMPANY_SEARCH,
  GET_MIDDLE_REPORT,

  //進階設定
  GET_SBTISETTING_API,
  POST_SBTISETTING_API,
  GET_CARBON_API,
  GET_CARBONSTRENGTH_API,
  POST_SBTICALCULATE_API,

  // 專案管理
  GET_PROJECTLIST_API,
  POST_PROJECTACTIVE_API,
  GET_PROJECT_API,
  POST_PROJECT_API,
  POST_PROJECTAUTH_API,
  GET_FUNCTIONLIST_API,
  GET_PROJECTSTATUS_API,
  GET_PROJECTSCHEDULE_API,
  GET_PROJECTPROGRESS_API,
  POST_PROJECTSTATUS_API,
  DELETE_PROJECTUSER_API,
  POST_USERAUTH_API,
  GET_FUNAUTHCLASS_API,
  POST_FUNCTION_API,
  GET_AUTHFUNCTION_API,
  GET_AUTHPROJECT_API,
  GET_FUNGROUP_API,
  GET_SAMEFABUSER_API,
  POST_NEWADMIN_API,
  POST_SBTISCOPE1PRO_API,
  POST_SBTISCOPE2PRO_API,
  GET_SBTIPRO_API,
  GET_MIDDLE_COMPARE_API,
  GET_PUBLICMESSAGE_API,
  ONE_KEY_OPENPROJECT,
  ONE_KEY_COMPLETEPROJECT,
  GET_ACTIONLOG_API,

  // 淨零路淨匯出
  POST_EXPORTSBTI_API,
  POST_IMPORTSBTI_API,

  // 教學影片連結
  GET_YOUTUBEVIDEO_API,

  //顧問查詢係數匯入
  GET_ADVISORLIST_API,
  POST_ADVISOR_API,
};
